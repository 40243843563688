import React, { useState } from "react";
import { PageProps, graphql } from "gatsby";

import { Query } from "@graphql-types";
import Layout from "@shared/layout";
import SEO from "@shared/seo";
import { CategoryFilterBar } from "@global";
import { CategoryName } from "../global/global.styles";
import Policy from "@components/policies/policy";
import { Container } from "@util/standard";

interface Props extends PageProps {
  data: Query;
}

export default function FaqsPage({ data }: Props) {
  if (data.sanityPoliciesPage == null) {
    return null;
  }

  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);
  const [mobileFilterVisible, setMobileFilterVisible] = useState(false);

  const handleChange = (index: number) => () => {
    setMobileFilterVisible(false);
    setCurrentCategoryIndex(index);
  };

  const { seo, policies } = data.sanityPoliciesPage;

  const currentPolicy = policies && policies[currentCategoryIndex];

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        {policies && (
          <Container margin="215px 0 0 0" tabletMargin="100px 0 0 0">
            <CategoryFilterBar
              mobileVisibility={mobileFilterVisible}
              setMobileVisibility={setMobileFilterVisible}
              padding="0 25%"
              filterResultChildren={<Policy policy={currentPolicy} />}
            >
              {policies.map((policy, index) => {
                if (policy == null) return null;
                return (
                  <CategoryName
                    onClick={handleChange(index)}
                    selected={index === currentCategoryIndex}
                    key={policy._key}
                    isLast={index === policies.length - 1}
                  >
                    {policy.title}
                  </CategoryName>
                );
              })}
            </CategoryFilterBar>
          </Container>
        )}
      </Layout>
    </>
  );
}

export const query = graphql`
  {
    sanityPoliciesPage {
      seo {
        ...sanitySeo
      }
      policies {
        _key
        _type
        title
        link {
          ...sanityLink
        }
        TextContent {
          ...sanityBlockContent
        }
      }
    }
  }
`;
